import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { getUserData } from '../../actions/userdata';
import { toggleLoader } from '../../actions/loader';
const LeftbarAdmin = (props) => {
  return (
    <>
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs fixed-left">
        <div className="sidenav-header">
          <div className="sidenav-toggler">
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
          <a className="navbar-brand m-0" href="#">
            <img src="/img/logo.png" className="navbar-brand-img h-100 w-15" alt="..." />
            <span className="ms-1 font-weight-bold"> Elderly </span>
          </a>
        </div>
        <div className="collapse navbar-collapse  w-auto">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/dashboard-nri'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-th-large" />
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/users-nri'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Users</span>
              </NavLink>
            </li>


            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/providers-nri'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Service Providers</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/nri-bookings'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Bookings</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/nri-coupons'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Coupons</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " activeClassName=" active" to={'/update-password'}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-users" />
                </div>
                <span className="nav-link-text ms-1">Update Password</span>
              </NavLink>
            </li>



          </ul>
        </div>
      </aside>
    </>
  );
}

const mapDispatchToProps = {
  toggleLoader, getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata?.userData,

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftbarAdmin);