import React, { useEffect } from 'react';
import { authme, displaySuccess } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../../actions/loader';
import { connect } from 'react-redux';
import { getUserData } from '../../actions/userdata';

const Topbar = (props) => {

  const logout = () => {
    localStorage.removeItem('token');
    displaySuccess('Logout Successfull');
    setTimeout(() => window.location.href = "/", 3000);
  }


  const getAuthData = async () => {
    var res = await authme()
    props?.getUserData(res.data)
  }
  useEffect(() => {
    getAuthData()
  }, [])

  return (
    <>
      <nav className="navbar navbar-main navbar-expand-lg border-bottom bg-primary">
        <div className="container-fluid py-1 px-3">
          <div className="d-sm-inline text-white d-none" style={{ fontSize: '18px', textTransform: 'capitalize' }}>

            {props?.userdata?.first_name} {props?.userdata?.last_name}
          </div>
          <div className="collapse navbar-collapse mt-sm-0 mt-2 ml-auto" style={{ flexGrow: 'unset' }}>
            <ul className="navbar-nav justify-content-end">
              {/* <li className="nav-item px-3 d-flex align-items-center">
                <Link to="/change-password" className="nav-link text-body p-0">
                  <i className="fa fa-cog text-white cursor-pointer" />
                </Link>
              </li> */}

              <li className="nav-item d-flex align-items-center">
                <a href="#" className="nav-link text-body text-white">
                  <img src="/img/logo.png" width="30px" className="rounded-circle" />
                  <a onClick={logout} className="d-sm-inline text-white d-none ml-2">Log out</a>
                </a>
              </li>
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="#" className="nav-link text-body p-0">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader, getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata?.userData,

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);