import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess, getNriInterest, getNriDetails } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import 'react-tagsinput/react-tagsinput.css'
import './style.css'

const UpdatePassword = (props) => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const updateProvider = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/update-password';
    var token = localStorage.getItem('token');
    var data = {};
    data = {
      current_password: currentPassword,
      password: newPass,
      confirm_password: confirmPass,
    }
    console.log(data)
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.reload()
          , 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/update-password">Update Password</Link ></li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Update Password</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Current Password</label>
                              <input type="text" className="form-control" placeholder="Current Password"
                                onChange={e => setCurrentPassword(e.target.value)} value={currentPassword}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >New Password</label>
                              <input type="text" className="form-control" placeholder="New Password"
                                onChange={e => setNewPass(e.target.value)} value={newPass}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Confirm Password</label>
                              <input type="text" className="form-control" placeholder="Confirm Password"
                                onChange={e => setConfirmPass(e.target.value)} value={confirmPass}
                              />
                            </div>
                          </div>
                        </div>

                        <button className="btn btn-primary mt-4"
                          onClick={() => {
                            updateProvider()

                          }}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div >
      </body >
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);