import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { getUserData } from '../../actions/userdata';
import { toggleLoader } from '../../actions/loader';
import LeftbarAdmin from './LeftbarAdmin';
const Leftbar = (props) => {
  return (
    <>
      {props?.userdata?.is_master_admin == false ?
        <LeftbarAdmin />
        :
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs fixed-left">
          <div className="sidenav-header">
            <div className="sidenav-toggler">
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
            <a className="navbar-brand m-0" href="#">
              <img src="/img/logo.png" className="navbar-brand-img h-100 w-15" alt="..." />
              <span className="ms-1 font-weight-bold"> Elderly </span>
            </a>
          </div>
          <div className="collapse navbar-collapse  w-auto">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/dashboard'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-th-large" />
                  </div>
                  <span className="nav-link-text ms-1">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/operations'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Operational Admins</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/users'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Users</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/approved-physios'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Approved Service Providers</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/physios'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Service Providers</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/provider-wallet'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Service Provider Wallet</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/tickets'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Tickets</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/payouts'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Provider Payouts</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/reported-user'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1"></span>Reported User
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/reported-physio'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Reported Service Provider</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/escrow'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Escrow Payouts</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/escrow-forecast'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Forecast Escrow</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/cancelled-booking'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Cancelled Bookings</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/coupons'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Coupons</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/interests'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Interests</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/specialties'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Specialties</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/predefined-messages'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Predefined Messages</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/transactions'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Transactions</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " activeClassName=" active" to={'/bookings'}>
                  <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="fas fa-users" />
                  </div>
                  <span className="nav-link-text ms-1">Bookings</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
      }
    </>
  );
}

const mapDispatchToProps = {
  toggleLoader, getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata?.userData,

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Leftbar);