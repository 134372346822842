import React, { useEffect, useState } from 'react';
import './dashboard.css';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
// import { getCards, getPurchaseList } from '../Utils/utils';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import { dashCounts } from '../Utils/utils';
import moment from 'moment';
import { getUserData } from '../actions/userdata';

const Dashboard = (props) => {
  const [count, setCount] = useState([]);
  const [num, setNum] = useState([]);
  const [physio, setPhysio] = useState('');
  const [Nurse, setNurse] = useState('');
  const [Geriatric, setGeriatric] = useState('');
  const [ALS, setALS] = useState('');
  const [thisMonthEarning, setThisMonthEarning] = useState('');
  const [totalCompletedBookings, setTotalCompletedBookings] = useState('');
  const [totalEarnings, setTotalEarnings] = useState('');
  const [totalThisMonthBookings, setTotalThisMonthBookings] = useState('');
  const [users, setUsers] = useState('');
  const total_earning_physio = physio.earnings?.reduce((total, i) => total + i.fees, 0) || 0;
  const total_earning_nurse = Nurse.earnings?.reduce((total, i) => total + i.fees, 0) || 0;
  const total_earning_geriatric = Geriatric.earnings?.reduce((total, i) => total + i.fees, 0) || 0;
  const total_earning_ALS = ALS.earnings?.reduce((total, i) => total + i.fees, 0) || 0;
  const getCounts = async () => {
    var res = await dashCounts()
    if (res.status == 200) {
      setPhysio(res.data.counts.Physiotherapists)
      setNurse(res.data.counts.Nurses)
      setGeriatric(res.data.counts?.["Geriatric Care"])
      setALS(res.data.counts?.["Assisted Living Supports"])
      // setPhysioCount(res.data.physios)
      setThisMonthEarning(res.data.thisMonthEarnings)
      setTotalCompletedBookings(res.data.totalCompletedBookings)
      setTotalEarnings(res.data.totalEarnings)
      setTotalThisMonthBookings(res.data.totalThisMonthBookings)
      setUsers(res.data.users)
    }
  }

  useEffect(() => {
    getCounts()
  }, [])
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <h6 className="h2 text-white d-inline-block mb-0">Dashboard</h6>
                      {/* <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><a href="#"><i className="fas fa-home" /></a></li>
                          <li className="breadcrumb-item"><a href="#">Components</a></li>
                        </ol>
                      </nav> */}
                    </div>
                  </div>


                  <div className="row" style={{ gap: '7px', paddingLeft: '15px' }}>
                    <div className="" style={{ width: '19%' }}>
                      <div className="card card-stats" >
                        <div className="card-body" >
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0"> Total Earning</h6>
                              <span className="h2 font-weight-bold mb-0">{totalEarnings}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '19%' }}>
                      {/* <div className="col-xl-2 col-md-2 custom-col-spacing" style={{ paddingRight: 15, paddingLeft: 15, width: '19%' }}> */}
                      <div className="card card-stats" >
                        <div className="card-body" >
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0"> Total Completed Bookings</h6>
                              <span className="h2 font-weight-bold mb-0">{totalCompletedBookings}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '19%' }}>
                      {/* <div className="col-xl-2 col-md-2 custom-col-spacing" style={{ paddingRight: 15, paddingLeft: 15, width: '30%' }}> */}
                      <div className="card card-stats" style={{ margin: 0 }}>
                        <div className="card-body" >
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">This Month Earnings <span className='font-weight-bold'>({moment(new Date()).format('MMM YYYY')}) </span></h6>
                              <span className="h2 font-weight-bold mb-0">{thisMonthEarning}</span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '19%' }}>
                      {/* <div className="col-xl-2 col-md-2 custom-col-spacing" style={{ paddingRight: 15, paddingLeft: 15, width: '30%' }}> */}
                      <div className="card card-stats" style={{
                        margin: 0
                      }}>
                        <div className="card-body" >
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0"> Total This Month Bookings <span className='font-weight-bold'>({moment(new Date()).format('MMM YYYY')})</span></h6>
                              <span className="h2 font-weight-bold mb-0">{totalThisMonthBookings}</span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '20%' }}>
                      {/* <div className="col-xl-2 col-md-2 custom-col-spacing" > */}
                      <div className="card card-stats" style={{ margin: 0 }}>
                        <div className="card-body" >
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Users</h6>
                              <span className="h2 font-weight-bold mb-0">{users}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Users</h6>
                              <span className="h2 font-weight-bold mb-0">{users}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div> */}
                  <div className='row' style={{ marginTop: '15px' }}>
                    {/* <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Physiotherapists</h6>
                              <span className="h2 font-weight-bold mb-0">{physio.count}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earning</h6>
                              <span className="h2 font-weight-bold mb-0">{total_earning_physio}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings</h6>
                              <span className="h2 font-weight-bold mb-0">{physio.bookings}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-xl-6 col-md-6 mb-2">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase font-weight-bold mb-0" >Physiotherapist ({physio.count})</h6>
                              {/* <span className="h2 font-weight-bold mb-0"></span> */}
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earnings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{total_earning_physio}</span>
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{physio.bookings}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 mb-2">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase font-weight-bold mb-0" >Nurse ({Nurse.count})</h6>
                            </div>
                            <div className="col text-right">
                              {/* <span className="h2 font-weight-bold mb-0">({Nurse.count})</span> */}
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earnings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{total_earning_nurse}</span>
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{Nurse.bookings}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-xl-6 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase font-weight-bold mb-0">Geriatric Care ({Geriatric.count})</h6>
                            </div>
                            <div className="col text-right">
                              {/* <span className="h2 font-weight-bold mb-0">({Geriatric.count})</span> */}
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earnings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{total_earning_geriatric}</span>
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{Geriatric.bookings}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase font-weight-bold mb-0">Assisted Living Supports ({ALS.count})</h6>
                            </div>
                            {/* <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">({ALS.count})</span>
                            </div>
                            */}
                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earnings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{total_earning_ALS}</span>
                            </div>

                          </div>
                          <div className="row mt-3 align-items-center">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings :</h6>
                            </div>
                            <div className="col text-right">
                              <span className="h2 font-weight-bold mb-0">{ALS.bookings}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earning</h6>
                              <span className="h2 font-weight-bold mb-0">{total_earning_nurse}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings</h6>
                              <span className="h2 font-weight-bold mb-0">{Nurse.bookings}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Geriatric Care</h6>
                              <span className="h2 font-weight-bold mb-0">{Geriatric.count}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earning</h6>
                              <span className="h2 font-weight-bold mb-0">{total_earning_geriatric}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Booking</h6>
                              <span className="h2 font-weight-bold mb-0">{Geriatric.bookings}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Assisted Living Supports</h6>
                              <span className="h2 font-weight-bold mb-0">{ALS.count}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Earning</h6>
                              <span className="h2 font-weight-bold mb-0">{total_earning_ALS}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <div className="card card-stats">
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h6 className="card-title text-uppercase text-muted mb-0">Total Bookings</h6>
                              <span className="h2 font-weight-bold mb-0">{ALS.bookings}</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="ni ni-active-40" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}


                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container-fluid mt--6">
              <div className="row my-4">
                <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
                  <div className="card">
                    <div className="card-header pb-0">
                      <div className="row">
                        <div className="col-lg-6 col-7">
                          <h6>Recent Purchases List</h6>

                        </div>
                        <div className="col-lg-6 col-5 my-auto text-right">
                          <div className="dropdown float-lg-end pe-4">
                            <a className="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="fa fa-ellipsis-v text-secondary" aria-hidden="true" />
                            </a>
                            <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                              <li><a className="dropdown-item border-radius-md" href="#">Action</a></li>
                              <li><a className="dropdown-item border-radius-md" href="#">Another action</a></li>
                              <li><a className="dropdown-item border-radius-md" href="#">Something else here</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pb-2">
                      <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email</th>
                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Offer</th>
                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (num.length === 0)
                                ?
                                <tr><td>No Recent Purchases.</td></tr>
                                :
                                num.map((data) => (
                                  <tr>
                                    <td>
                                      <div className="d-flex px-2 py-1">
                                        <div>
                                          {data.media !== null ?
                                            <img src={data.media.url} className="avatar avatar-sm mr-3" />
                                            : <img src="img2/small-logos/logo-xd.svg" className="avatar avatar-sm mr-3" />}

                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                          {data.user !== null ?
                                            <h6 className="mb-0 text-sm">{data.user.first_name} {data.user.last_name}</h6>
                                            : ""}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {data.user !== null ?
                                        <span className="text-xs font-weight-bold">{data.user.email}</span>
                                        : ""}

                                    </td>
                                    <td className="align-middle text-center text-sm">
                                      <span className="text-xs font-weight-bold">{data.offer.name} </span>
                                    </td>
                                    <td className="align-middle">
                                      <div className="progress-wrapper w-75 mx-auto">
                                        <div className="progress-info">
                                          <div className="progress-percentage">
                                            <span className="text-xs font-weight-bold">{data.status}</span>
                                          </div>
                                        </div>
                                        <div className="progress">
                                          <div className="progress-bar bg-gradient-info w-60" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="table-actions">
                                      <Link className="btn btn-light" data-toggle="tooltip" data-original-title="Edit product">
                                        <i className="fas fa-user-edit" />
                                      </Link>

                                    </td>
                                  </tr>
                                ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div> */}
          </main>
        </div >
      </body >
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader, getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata?.userData,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
